.SidebarButton {
    width: 100%;
    padding: 2%;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .15rem;
}

.SidebarButton-Name {
    width: 100%;
}
